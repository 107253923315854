// import { useEffect } from 'react'
import { constants, ethers } from 'ethers'
import {
  useEthers,
  useContractCall,
  useContractFunction,
  useContractCalls,
} from '@usedapp/core'

import { Contract } from '@ethersproject/contracts'

import ReferralContract from '../chain-info/contracts/ReferralContract.json'
import networkMapping from '../network-config'

const { abi: abiReferral } = ReferralContract
const referralContractInterface = new ethers.utils.Interface(abiReferral)

export function useGetJoinersAddress(programDetail, contractAddress) {
  const [uidList] =
    useContractCall(
      programDetail.code &&
        programDetail.code !== '' &&
        contractAddress && {
          abi: referralContractInterface,
          address: contractAddress,
          method: 'getJoinersAddress',
          args: [programDetail.code],
        }
    ) ?? []

  return uidList
}

export function useGetUidJoiner(programDetail, joiners, contractAddress) {
  return useContractCalls(
    programDetail.code &&
      programDetail.code !== '' &&
      joiners &&
      joiners.length > 0 &&
      joiners[0] &&
      contractAddress
      ? joiners.map(addr => ({
          abi: referralContractInterface,
          address: contractAddress,
          method: 'addressJoined',
          args: [programDetail.code, addr],
        }))
      : []
  )
}
export function useGetRefereesLevel1(
  openUsers,
  programDetail,
  joiners,
  contractAddress
) {
  return useContractCalls(
    openUsers &&
      programDetail.code &&
      programDetail.code !== '' &&
      joiners &&
      joiners.length > 0 &&
      typeof joiners[0] !== 'undefined' &&
      contractAddress
      ? joiners.map(addr => ({
          abi: referralContractInterface,
          address: contractAddress,
          method: 'getTotalRefereesL1',
          args: [programDetail.code, addr],
        }))
      : []
  )
}

export function useGetRefereesLevel2(
  openUsers,
  programDetail,
  joiners,
  contractAddress
) {
  return useContractCalls(
    openUsers &&
      programDetail.code &&
      programDetail.code !== '' &&
      joiners &&
      joiners.length > 0 &&
      typeof joiners[0] !== 'undefined' &&
      contractAddress
      ? joiners.map(addr => ({
          abi: referralContractInterface,
          address: contractAddress,
          method: 'getTotalRefereesL2',
          args: [programDetail.code, addr],
        }))
      : []
  )
}

export function useJoined(uid, contractAddress) {
  const [addressJoined] =
    useContractCall(
      contractAddress &&
        uid && {
          abi: referralContractInterface,
          address: contractAddress,
          method: 'userJoined',
          args: [uid],
        }
    ) ?? []

  return addressJoined
}

export function useCheckJoin(programDetail, uid, contractAddress) {
  const [joined] =
    useContractCall(
      uid &&
        programDetail.code &&
        programDetail.code !== '' &&
        contractAddress && {
          abi: referralContractInterface,
          address: contractAddress,
          method: 'uidJoined',
          args: [programDetail.code, uid],
        }
    ) ?? []

  return joined
}

export function useContractMethodSingle(methodName) {
  const { chainId } = useEthers()
  const contractAddress = chainId
    ? networkMapping[String(chainId)]['referral_contract']
    : constants.AddressZero
  const contract = new Contract(contractAddress, referralContractInterface)

  const { state, send } = useContractFunction(contract, methodName)

  return { state, send }
}
