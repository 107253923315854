// import { useEffect } from 'react'
import { constants, ethers } from 'ethers'
import {
  useEthers,
  useContractCall,
  useContractFunction,
  useContractCalls,
} from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'

import ReferralAdminContract from '../chain-info/contracts/ReferralAdminContract.json'
import networkMapping from '../network-config'

const { abi: abiReferral } = ReferralAdminContract
const referralAdminContractInterface = new ethers.utils.Interface(abiReferral)

export function useContractMethod(methodName) {
  const { chainId } = useEthers()
  const contractAddress = chainId
    ? networkMapping[String(chainId)]['referral_admin_contract']
    : constants.AddressZero
  const contract = new Contract(contractAddress, referralAdminContractInterface)

  const { state, send } = useContractFunction(contract, methodName)

  return { state, send }
}

export function useGetProgram(code, contractAddress) {
  const { account } = useEthers()

  const program =
    useContractCall(
      account &&
        code &&
        contractAddress && {
          abi: referralAdminContractInterface,
          address: contractAddress,
          method: 'programs',
          args: [code],
        }
    ) ?? []
  return program === [] ? null : program
}

export function useCallAdmin(functionCall, contractAddress, args = []) {
  const [val] =
    useContractCall(
      contractAddress && {
        abi: referralAdminContractInterface,
        address: contractAddress,
        method: functionCall,
        args: args,
      }
    ) ?? []

  return val
}

export function useGetHoldersTotal(programDetail, contractAddress) {
  const [val] =
    useContractCall(
      programDetail.code &&
        programDetail.code !== '' &&
        contractAddress && {
          abi: referralAdminContractInterface,
          address: contractAddress,
          method: 'getTotalHolders',
          args: [programDetail.code],
        }
    ) ?? []
  return val
}
export function useGetIncentiveHolder(
  programDetail,
  totalHolders,
  contractAddress
) {
  const indexes = Array.from(Array(totalHolders).keys())

  return useContractCalls(
    totalHolders > 0 &&
      programDetail.code &&
      programDetail.code !== '' &&
      indexes.length > 0 &&
      contractAddress
      ? indexes.map(index => ({
          abi: referralAdminContractInterface,
          address: contractAddress,
          method: 'holders',
          args: [programDetail.code, index],
        }))
      : []
  )
}

export function useGetIncentive(
  programDetail,
  holdersAddress,
  contractAddress
) {
  return useContractCalls(
    programDetail.code &&
      programDetail.code !== '' &&
      holdersAddress &&
      holdersAddress.length > 0 &&
      contractAddress
      ? holdersAddress.map(addr => ({
          abi: referralAdminContractInterface,
          address: contractAddress,
          method: 'incentiveHold',
          args: [programDetail.code, addr ? addr.toString() : ''],
        }))
      : []
  )
}

export function useGetUsersIncentive(
  openUsers,
  programDetail,
  joiners,
  contractAddress
) {
  return useContractCalls(
    openUsers &&
      programDetail.code &&
      programDetail.code !== '' &&
      joiners &&
      joiners.length > 0 &&
      typeof joiners[0] !== 'undefined' &&
      contractAddress
      ? joiners.map(addr => ({
          abi: referralAdminContractInterface,
          address: contractAddress,
          method: 'incentivePaid',
          args: [programDetail.code, addr],
        }))
      : []
  )
}
