const configDefault = {
  development: {
    appUrl: 'http://localhost:3000',
    '97': {
      nameNetwork: 'BSC Testnet',
      // valid_user_contract: '0x5734169930CDc94274F8Bc4E4cf3B4ea86D269eE',
      rir_token: '0x6768BDC5d03A87942cE7cB143fA74e0DadE0371b',
      // meo_token: '0xD0E081Ace2c861457516FE6F47F57C3010a54AE7',
      referral_admin_contract: '0x4879FC3aA1c869280F331e043B06f4D38f48cF0c',
      referral_contract: '0x60308D60DD01758B2b11908A52b867557E38EF43',
    },
    '56': {
      nameNetwork: 'BSC',
      // valid_user_contract: '0x5734169930CDc94274F8Bc4E4cf3B4ea86D269eE',
      rir_token: '0x30FB969AD2BFCf0f3136362cccC0bCB99a7193bC',
      // meo_token: '0xD0E081Ace2c861457516FE6F47F57C3010a54AE7',
      referral_admin_contract: '0xc81113C6ff6eB03281A53b1127925C263D4EFfBE',
      referral_contract: '0xB5633874574b236601cdDb015ca253472ce7315f',
    },
  },
  production: {
    appUrl: 'https://referral-demo.1alo.com',
    '97': {
      nameNetwork: 'BSC Testnet',
      // valid_user_contract: '0x5734169930CDc94274F8Bc4E4cf3B4ea86D269eE',
      rir_token: '0x6768BDC5d03A87942cE7cB143fA74e0DadE0371b',
      // meo_token: '0xD0E081Ace2c861457516FE6F47F57C3010a54AE7',
      referral_admin_contract: '0x4879FC3aA1c869280F331e043B06f4D38f48cF0c',
      referral_contract: '0x60308D60DD01758B2b11908A52b867557E38EF43',
    },
    '56': {
      nameNetwork: 'BSC',
      // valid_user_contract: '0x5734169930CDc94274F8Bc4E4cf3B4ea86D269eE',
      rir_token: '0x30FB969AD2BFCf0f3136362cccC0bCB99a7193bC',
      // meo_token: '0xD0E081Ace2c861457516FE6F47F57C3010a54AE7',
      referral_admin_contract: '0xc81113C6ff6eB03281A53b1127925C263D4EFfBE',
      referral_contract: '0xB5633874574b236601cdDb015ca253472ce7315f',
    },
  },
}

export default {
  ...configDefault[process.env.NODE_ENV],
  version: '0.0.2',
}
